$(function () {
  // var sample1 = '';
  // var smaple2 = '';
  
  // ieでobject-fitするやつ
  objectFitImages();

  // アコーディオン
  $(".flow_toggle").next().hide();
  $(".flow_toggle").on("click", function(){
    var nakami = $(this).next();
    if(nakami.is(":visible")){
      nakami.slideUp();
      $(this).removeClass("visible")
    }else{
      nakami.slideDown();
      $(this).addClass("visible");
    }
    return false;
  });

  // slick設定
  // TOPメインビジュアル
  $(".slides_index_main").slick({
    slidesToShow: 1,
    autoplay: true,
    prevArrow: '<button class="slide-arrow prev-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_prev.png"></button>',
    nextArrow: '<button class="slide-arrow next-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_next.png"></button>',
  });
  $(".slides_index_main_sp").slick({
    slidesToShow: 1,
    autoplay: true,
    prevArrow: '<button class="slide-arrow prev-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_prev.png"></button>',
    nextArrow: '<button class="slide-arrow next-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_next.png"></button>',
  });

  // TOP実績一覧
  $(".slides_index_works").slick({
    autoplay: true,
    // autoplaySpeed: 1000,
    // speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        prevArrow: '<button class="slide-arrow prev-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_prev.png"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"><img src="http://marusangiken.sakura.ne.jp/cms/wp-content/themes/marusan/img/icon_arrow_next.png"></button>',
        arrows: true,
        centerMode: false,
      }
    }]
  });
  // TOPバナーリスト
  $(".bnrs_1").slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
  });
  $(".bnrs_sp").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  });

  // 実績詳細ページ　上のメイン画像
  $('.slider_works_detail').slick({
    arrows: false,
    asNavFor: '.thumb_works_detail',
  });
  // 実績詳細ページ　下のサムネイル画像
  $('.thumb_works_detail').slick({
    asNavFor: '.slider_works_detail',
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  });

  // スムーズスクロール
  var urlHash = location.hash;
  var adjust = (navigator.userAgent.indexOf('iPhone') < 0 && navigator.userAgent.indexOf('iPod') < 0 && navigator.userAgent.indexOf('Android') < 0) ? 100 : 10;
  if (urlHash) {
    $("body, html").stop().scrollTop(0);
    setTimeout(function () {
      scrollToAnker(urlHash, adjust);
    }, 100);
  }
  // 通常のクリック時
  $('a[href*="#"]').on("click", function(){
    var href = $(this).attr("href");
    clickAnker(href, adjust);
  });

  function clickAnker(href, adjust){
    if (href !== "#") {
      var hash = href === "" ? 'html' : href;
      var hash = "#" + hash.split("#")[1];
      var speed = 800;
      if (href == "#service") adjust = 70;
      if (href == "#csr") adjust = 40;
      if (href == "#recruit") adjust = -55;
      scrollToAnker(hash, adjust)
      $("html, body").animate({ scrollTop: position }, speed, "swing");
    }
  }

  // 関数：スムーススクロール
  function scrollToAnker(hash, adjust){
    var speed = 800;
    var target = $(hash);
    var position = target.offset().top - adjust;
    $("html, body").stop().animate({scrollTop: position}, speed, "swing");
    return false;
  }

  // 実行：アコーディオン
  // $(".acor");

  // 関数：アコーディオン
  // function (){};
});